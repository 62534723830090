import {Injectable} from '@angular/core';
import {KundeEntitiesEffects} from './kunde-entities.effects';
import {KundeLeistungsempfaengerActions} from '../actions/kunde-leistungsempfaenger.actions';


@Injectable()
export class KundeLeistungsempfaengerEffects {

  constructor(
    private kundeEntitiesEffects: KundeEntitiesEffects,
  ) {
  }

  // FIXME: FilterableDto anpassen (in den read-Effekten), dann wieder einkommentieren.
  // readonly readKunden$ = this.kundeEntitiesEffects.readKunden$(
  //   KundeLeistungsempfaengerActions.readKunden,
  //   KundeLeistungsempfaengerActions.setDisplayedIds,
  //   KundeLeistungsempfaengerActions.clearDisplayedKunden,
  // );
  //
  // readonly readMoreKunden$ = this.kundeEntitiesEffects.readKunden$(
  //   KundeLeistungsempfaengerActions.readMoreKunden,
  //   KundeLeistungsempfaengerActions.addDisplayedIds,
  //   KundeLeistungsempfaengerActions.clearDisplayedKunden,
  // );

}
