import {Injectable} from '@angular/core';
import {KundeEntitiesEffects} from './kunde-entities.effects';
import {KundeTableActions} from '../actions/kunde-table.actions';


@Injectable()
export class KundeTableEffects {

  constructor(
    private kundeEntitiesEffects: KundeEntitiesEffects,
  ) {
  }

  // FIXME: FilterableDto anpassen (in den read-Effekten), dann wieder einkommentieren.
  // readonly readKunden$ = this.kundeEntitiesEffects.readKunden$(
  //   KundeTableActions.readKunden,
  //   KundeTableActions.setDisplayedIds,
  //   KundeTableActions.clearDisplayedKunden,
  // );

}
