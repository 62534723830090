import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';
import {concatMap, of, switchMap, take, tap} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {ActionCreator, Store} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {BelegDTO, BelegService, PageableDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {TypedAction} from '@ngrx/store/src/models';
import {FakturierungsbelegEntitiesSelectors} from '../selectors/fakturierungsbeleg-entities.selectors';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {SentryActions} from '../actions/sentry.actions';
import {DeleteFakturierungsbelegDialogActions} from '../actions/delete-fakturierungsbeleg-dialog.actions';


@Injectable()
export class FakturierungsbelegEntitiesEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private belegService: BelegService,
    private store: Store<AppState>,
  ) {
  }

  /**
   * Der Effekt erlaubt asynchrones Laden von Fakturierungsbelegen durch serverseitige Datenanfragen.
   * Dabei steuert er die Auslösung von Actions basierend auf dem Erfolg oder Misserfolg der Anfrage.
   *
   * @property onAction - Action Creator, der ausgeführt wird, um den Effekt zu starten
   * @property successAction - Action Creator, der ausgeführt wird, wenn die Daten erfolgreich vom Server abgerufen wurden
   * @property failureAction - Action Creator, der ausgeführt wird, wenn beim Abrufen der Daten ein Fehler aufgetreten ist
   */
  readonly readFakturierungsbelege$ = (
    onAction: ActionCreator<any, (props: {
      betriebId: string,
      pageableDto: PageableDTO,
    }) => ({
      betriebId: string, pageableDto: PageableDTO,
    } & TypedAction<any>)>,
    successAction?: ActionCreator<any, (props: { belegDtos: BelegDTO[], }) => ({
      belegDtos: BelegDTO[]
    } & TypedAction<any>)>,
    failureAction?: ActionCreator<any, (props: { error: MappedHttpErrorResponse, }) => ({
      error: MappedHttpErrorResponse
    } & TypedAction<any>)>,
  ) => createEffect(
    () => this.actions$.pipe(
      ofType(onAction),
      switchMap(({ betriebId, pageableDto }) => {
        return this.belegService.readBelege(
          betriebId,
          pageableDto
        ).pipe(
          switchMap(belegPageDto => {
            console.debug('read fakturierungsbelege succeeded.');

            return [
              FakturierungsbelegEntitiesActions.readFakturierungsbelegeSuccess({ belegDtos: belegPageDto.content }),
              FakturierungsbelegEntitiesActions.countFakturierungsbelegeElementsSuccess({ totalElements: belegPageDto.totalElements }),
              FakturierungsbelegEntitiesActions.countFakturierungsbelegePagesSuccess({ totalPages: Math.ceil(belegPageDto.totalElements / (belegPageDto.pageable.size || 1)) }),
              ...(successAction ? [successAction({ belegDtos: belegPageDto.content })] : []),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            switchMap(error => {
              console.error('read fakturierungsbelege failed.', 'error:', error);

              return [
                FakturierungsbelegEntitiesActions.readFakturierungsbelegeFailure({ error }),
                ...(failureAction ? [failureAction({ error })] : []),
              ];
            }),
          )),
        );
      }),
    )
  );

  /**
   * Generisches Error-Handling für das Laden von Fakturierungsbelegen.
   */
  readonly readFakturierungsbelegeFailed$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.readFakturierungsbelegeFailure),
      map(({ error }) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden von Fakturierungsbelegen. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Keine Fakturierungsbelege gefunden.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Fakturierungsbelege. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          });
      }),
    ), { dispatch: false }
  );

  /**
   * Effekt zum Laden eines Fakturierungsbelegs anhand der ID, sofern er noch nicht im Store vorhanden ist.
   */
  readonly loadFakturierungsbelegByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.loadFakturierungsbelegByIdIfAbsent),
      concatMap(action => this.store.select(FakturierungsbelegEntitiesSelectors.belegById(action.belegId)).pipe(
        take(1),
        map(beleg => ({ action, beleg }))
      )),
      filter(data => !data.beleg),
      map(data => FakturierungsbelegEntitiesActions.getFakturierungsbelegById({
        betriebId: data.action.betriebId,
        belegId: data.action.belegId,
      })),
    ),
  );

  /**
   * Effekt zum Laden eines Fakturierungsbelegs anhand der ID.
   */
  readonly getFakturierungsbelegById$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.getFakturierungsbelegById),
      concatMap(({ betriebId, belegId }) => {
        return this.belegService.getBeleg(betriebId, belegId).pipe(
          map((belegDto) => {
            console.debug(
              'read belegDto by ID succeeded. belegDto:',
              belegDto,
            );

            return FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdSuccess({ belegDto });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              console.error(
                'read beleg by ID failed. belegId:',
                belegId,
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden eines Fakturierungsbelegs anhand der ID.
   */
  readonly getFakturierungsbelegByIdFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdFailure),
      map(({ error }) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Fakturierungsbeleg nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    { dispatch: false },
  );

  /**
   * Effekt zum Erstellen eines Fakturierungsbelegs.
   */
  readonly createFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbeleg),
      concatMap(({ betriebId, createBelegRequestDto }) => {
        return this.belegService.createBeleg(betriebId, createBelegRequestDto).pipe(
          concatMap(createdBelegDto => {
            console.debug(
              'create fakturierungsbeleg entity succeeded. belegId:',
              createdBelegDto.id,
            );

            const successActions: TypedAction<any>[] = [
              FakturierungsbelegEntitiesActions.createFakturierungsbelegSuccess({ belegDto: createdBelegDto }),
            ];

            return successActions;
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              console.error(
                'create fakturierungsbeleg entity failed',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.createFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Erstellen eines Fakturierungsbelegs.
   */
  readonly createFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbelegSuccess),
      tap(() => {
        this.snackbar.open('Fakturierungsbeleg erfolgreich angelegt', undefined, { duration: 5000 });
      }),
    ), { dispatch: false }
  );

  /**
   * Error-Handling für das Erstellen eines Fakturierungsbelegs.
   */
  readonly createFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbelegFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Erstellen des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Erstellen des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Updaten eines Fakturierungsbelegs.
   */
  readonly updateFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.updateFakturierungsbeleg),
      concatMap(({ betriebId, belegId, updateBelegRequestDto }) => {
        return this.belegService.updateBeleg(betriebId, belegId, updateBelegRequestDto).pipe(
          switchMap(updatedBelegDto => {
            console.debug(
              'update beleg entity succeeded. belegId:',
              belegId,
            );
            return [
              FakturierungsbelegEntitiesActions.updateFakturierungsbelegSuccess({ belegDto: updatedBelegDto }),
            ];
          }),

          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              console.error(
                'update beleg entity failed. belegId:',
                belegId,
                'error:',
                error,
              );
              return FakturierungsbelegEntitiesActions.updateFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Updaten eines Fakturierungsbelegs.
   */
  readonly updateFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.updateFakturierungsbelegSuccess),
      tap(() => {
        this.snackbar.open('Fakturierungsbeleg erfolgreich aktualisiert', undefined, { duration: 5000 });
      }),
    ), { dispatch: false }
  );

  /**
   * Error-Handling für das Updaten eines Fakturierungsbelegs.
   */
  readonly updateFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.updateFakturierungsbelegFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Aktualisieren des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbeleg),
      concatMap(({ betriebId, belegId }) => {
        return this.belegService.deleteBeleg(betriebId, belegId).pipe(
          switchMap(() => {
            console.debug(
              'delete beleg succeeded. belegId:',
              belegId,
            );

            return [
              FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess({ belegId }),
              DeleteFakturierungsbelegDialogActions.close(), // Ersetze oder entferne falls nicht zutreffend
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              console.error(
                'delete beleg failed.',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess),
      tap(() => {
        this.snackbar.open('Fakturierungsbeleg erfolgreich gelöscht', undefined, { duration: 5000 });
      }),
    ), { dispatch: false }
  );

  /**
   * Error-Handling für das Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure),
      map(({ error }) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Fakturierungsbeleg nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

}
