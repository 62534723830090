import {Injectable} from '@angular/core';
import {FakturierungsbelegEntitiesEffects} from './fakturierungsbeleg-entities.effects';
import {FakturierungsbelegTableActions} from '../actions/fakturierungsbeleg-table.actions';


@Injectable()
export class FakturierungsbelegTableEffects {

  constructor(
    private fakturierungsbelegEntitiesEffects: FakturierungsbelegEntitiesEffects,
  ) {
  }

  readonly readBelege$ = this.fakturierungsbelegEntitiesEffects.readFakturierungsbelege$(
    FakturierungsbelegTableActions.readFakturierungsbelege,
    FakturierungsbelegTableActions.setDisplayedIds,
    FakturierungsbelegTableActions.clearDisplayedFakturierungsbelege,
  );

}
