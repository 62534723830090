import {TableSettings} from '../../interfaces/table-setting.interface';
import {PageableDTO} from '../../openapi/fakturierung-openapi';


export enum FakturierungsbelegColumn {
  Status = 'status',
  Faelligkeit = 'faelligkeit',
  Rechnungsnummer = 'rechnungsnummer',
  Kunde = 'kunde',
  Rechnungsdatum = 'rechnungsdatum',
  Nettobetrag = 'nettobetrag',
  Bruttobetrag = 'bruttobetrag',
  Actions = 'actions',
}

export interface FakturierungsbelegTableState {
  displayedIds: string[];
  tableSettings: TableSettings;
  pageableDto: PageableDTO;
  totalPages: number;
  isLoaded: boolean;
}
