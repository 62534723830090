import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {KundeDTO, PageableDTO} from '../../openapi/fakturierung-openapi';


export class KundeLeistungsempfaengerActions {

  // FIXME: FilterableDto anpassen
  // public static readKunden = createAction(
  //   '[Kunde Leistungsempfaenger] Read kunden at leistungsempfaenger component.',
  //   props<{
  //     betriebId: string,
  //     filterableDto: FilterableDTO,
  //     pageableDto: PageableDTO,
  //   }>(),
  // );

  public static setDisplayedIds = createAction(
    '[Kunde Leistungsempfaenger] Set kunden ids that should be displayed at leistungsempfaenger component.',
    props<{
      kundeDtos: KundeDTO[],
    }>(),
  );

  public static clearDisplayedKunden = createAction(
    '[Kunde Leistungsempfaenger] Clear kunden that should be displayed at leistungsempfaenger component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  // FIXME: FilterableDto anpassen
  // public static readMoreKunden = createAction(
  //   '[Kunde Leistungsempfaenger] Read more kunden at leistungsempfaenger component.',
  //   props<{
  //     betriebId: string,
  //     filterableDto: FilterableDTO,
  //     pageableDto: PageableDTO,
  //   }>(),
  // );

  public static addDisplayedIds = createAction(
    '[Kunde Leistungsempfaenger] Adds more kunden ids that should be displayed at leistungsempfaenger component.',
    props<{
      kundeDtos: KundeDTO[],
    }>(),
  );

  public static updateTextFilter = createAction(
    '[Kunde Leistungsempfaenger] Updates the text-filter at leistungsempfaenger component.',
    props<{
      filter: string[],
    }>(),
  );

  public static triggerReadMoreKunden = createAction(
    '[Kunde Leistungsempfaenger] Updates the pageableDto to load more options at leistungsempfaenger component.',
  );

}
