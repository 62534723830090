import {createAction, props} from '@ngrx/store';
import {TableSettings} from '../../interfaces/table-setting.interface';
import {BelegDTO, PageableDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class FakturierungsbelegTableActions {

  private static readonly prefix = '[Fakturierungsbelege Table] ';

  public static readFakturierungsbelege = createAction(
    this.prefix + 'Read fakturierungsbelege.',
    props<{
      betriebId: string,
      pageableDto: PageableDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    this.prefix + 'Set fakturierungsbeleg ids that should be displayed at fakturierungsbeleg-table component.',
    props<{
      belegDtos: BelegDTO[],
    }>(),
  );

  public static clearDisplayedFakturierungsbelege = createAction(
    this.prefix + 'Clear fakturierungsbelege that should be displayed at fakturierungsbeleg-table component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateSortingEnum = createAction(
    this.prefix + 'Updates the sorting enum.',
    props<{
      sorting: any[],
    }>(),
  );

  public static changeTableSettings = createAction(
    this.prefix + 'Changed table settings.',
    props<{ tableSettings: TableSettings }>(),
  );

  public static changePage = createAction(
    this.prefix + 'Changed pageable.',
    props<{ pageableDto: PageableDTO }>(),
  );

  public static updateSearchValue = createAction(
    this.prefix + 'Changed search value',
    props<{ searchValue: string[] }>(),
  );


}
