import {createAction, props} from '@ngrx/store';
import {TableSettings} from '../../interfaces/table-setting.interface';
import {KundeDTO, PageableDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class KundeTableActions {

  // FIXME: FilterableDto anpassen
  // public static readKunden = createAction(
  //   '[Kunden Table] Read kunden.',
  //   props<{
  //     betriebId: string,
  //     filterableDto: FilterableDTO,
  //     pageableDto: PageableDTO,
  //   }>(),
  // );

  public static setDisplayedIds = createAction(
    '[Kunden Effects] Set kunden ids that should be displayed at kunde-table component.',
    props<{
      kundeDtos: KundeDTO[],
    }>(),
  );

  public static clearDisplayedKunden = createAction(
    '[Kunden Effects] Clear kunden that should be displayed at kunde-table component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateSortingEnum = createAction(
    '[Kunde Table] Updates the sorting enum.',
    props<{
      sorting: any[],
    }>(),
  );

  public static changeTableSettings = createAction(
    '[Kunde Table] Changed table settings.',
    props<{ tableSettings: TableSettings }>(),
  );

  public static changePage = createAction(
    '[Kunde Table] Changed pageable.',
    props<{ pageableDto: PageableDTO }>(),
  );

  public static updateSearchValue = createAction(
      '[Kunde Table] Changed search value',
      props<{ filter: string[] }>(),
  );

}
